<template>
  <section
    id="content"
    style="margin-bottom: 80px "
  >
    <v-card
      class="mx-auto py-1 mb-2 border-radius 0px;"
      elevation="24"
      light
      max-width="95%"
      style="margin: -64px 70px 0px 0px; border-radius 0px"
      v-bind="$attrs"
      tile
      outlined
    >
      <v-sheet
        max-width="1000"
        class="mx-auto py-0 py-md-6"
      >
        <v-container id="vineyards">
          <v-card-title
            :class="[$vuetify.breakpoint.mdAndUp ? 'display-2' : 'display-1']"
            class="justify-center font-weight-bold grey--text text--darken-3 mb-3 text-center"
          >
            Under the Toad Tree
          </v-card-title>
          <v-card-text
            class="text-left text-center title font-weight-light mx-auto "
          >
            Hidden away in the middle of an Indigenous Forest, We offer you a
            break away from the hustle and bustle.... From Rustic Breakfasts,
            Iced Coffees, milkshakes and more...... Join us for walks in our
            hidden trails taking in the fresh forest air..... Spectacular bird
            life and more.... From weddings, birthdays to many more for an
            experience never to be forgotten!
          </v-card-text>
        </v-container>
        <div class="text-center">
          <v-card-text class="subtitle-1">
            <em>Please note we do have a liquor license.</em> &nbsp;<i
              class="fas fa-glass-martini-alt"
            />
          </v-card-text>
        </div>

        <v-card
          class="mx-auto"
          height="85vh"
          width="80vw"
          tile
        >
          <v-carousel
            cycle
            height="85vh"
            width="80vw"
            hide-delimiters
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.src"
            />
          </v-carousel>
        </v-card>

        <section
          id="details"
          class="mx-auto pt-5 pb-0 mb-2"
        >
          <v-container>
            <v-row justify="center">
              <v-col
                v-for="([icon, info1, info2, href], i) in details"
                :key="i"
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <a
                    style="text-decoration: none;"
                    :href="href"
                    target="_blank"
                    rel="noopener"
                  >
                    <v-icon
                      color="thirdary"
                      size="64"
                      class="mb-3"
                      v-text="icon"
                    />

                    <div
                      color="thirdary"
                      class="mb-2 title"
                      v-text="info1"
                    />
                    <div
                      color="thirdary"
                      v-text="info2"
                    />
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-sheet>
      <v-sheet
        class="mx-auto py-0 mb-2"
        color="grey lighten-2"
      >
        <p style="text-align: center;">
          <strong>Open</strong>
          <br>
          <em>Monday:</em> closed
          <br>
          <em>Tuesday – Friday:</em> 07:30-16:00
          <br>
          <em>Saturday & Sunday & Public Holidays:</em> 07:30-13:00
          <br>
        </p>
      </v-sheet>
    </v-card>
  </section>
</template>

<script>
  export default {
    name: 'SampleContents',
    data: () => ({
      items: [
        {
          src: require('@/assets/Main/toadtree/toilet.webp')
        },
        {
          src: require('@/assets/Main/toadtree/junglejim.webp')
        },
        {
          src: require('@/assets/Main/toadtree/trampoline.webp')
        },
        {
          src: require('@/assets/Main/toadtree/playground(1).webp')
        },
        {
          src: require('@/assets/Main/toadtree/table2.webp')
        },
        {
          src: require('@/assets/Main/toadtree/table1.webp')
        }
      ],
      details: [
        [
          'mdi-crosshairs-gps',
          'Twinstreams Indigenous Nursery',
          'Mtunzini, KZN, 3867',
          'https://www.google.com/maps/place/Twinstreams+Indigenous+Nursery/@-28.9866208,31.725773,17z/data=!3m1!4b1!4m5!3m4!1s0x1efa0207765001cd:0xb1ef24fea994c6bf!8m2!3d-28.9866208!4d31.7279617'
        ],
        [
          'mdi-email',
          'info@twinstreams.co.za ',
          '',
          'mailto:info@twinstreams.co.za '
        ],
        ['mdi-phone', '074 189 6354', '', 'tel:+27-74-189-6354']
      ],
      socials: [
        [
          'https://www.facebook.com/blockhousekitchen.constantia/',
          'mdi-facebook',
          'Facebook'
        ],
        [
          'https://www.instagram.com/blockhousekitchen/',
          'mdi-instagram',
          'Instagram'
        ],
        ['https://blockhousekitchen.co.za/', 'mdi-web', 'Website']
      ]
    })
  }
</script>

<style>
.v-card {
  border-radius: 0px;
}
</style>
